<template>
  <k-layout>
    <main class="tw-mt-8 tw-px-4 tw-mb-24 sm:tw-mt-24 lg:tw-mt-32">
      <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-8 lg:tw-space-y-0 lg:tw-space-x-8">
          <div class="tw-w-full tw-flex tw-flex-col tw-place-items-center">
            <h1 class="tw-heading-1 tw-font-bold tw-text-centers">{{ $t('404.title') }}</h1>

            <div class="tw-max-w-3xl tw-mt-12 tw-w-full">
              <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-end">
                <h4 class="tw-heading-4">{{ $t('404.subtitle') }}</h4>
                <p class="tw-mt-6 tw-flex-shrink-0 lg:tw-mt-0 tw-text-base">
                  <k-link :to="{ name: 'project-index' }" class="tw-text-gray-500">{{ $t('404.action') }} ›</k-link>
                </p>
              </div>

              <div class="tw-mt-4 tw-space-y-4 tw-text-left">
                <template v-if="!slicedProjects || !slicedProjects.length">
                  <project-preview-skeleton />
                  <project-preview-skeleton />
                  <project-preview-skeleton />
                </template>
                <template v-else>
                  <project-preview :project="project" v-for="project in slicedProjects" :key="project.id" />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </k-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import ProjectPreview from './../projects/components/project-preview-new'
import ProjectPreviewSkeleton from './../projects/components/project-preview-skeleton-new'
import { getRouteLocale } from '@web/constants/language'

export default {
  components: {
    ProjectPreview,
    ProjectPreviewSkeleton
  },
  created() {
    this.$router.push({ path: this.locale ? `/${ this.locale }/404` : '/404', params: { locale: this.locale } })
  },
  mounted() {
    if (!this.projects || !this.projects.length) {
      this.$store.dispatch('NEW_PROJECTS/fetchAllProjects')
    }
  },
  computed: {
    ...mapGetters({
      projects: 'NEW_PROJECTS/getAllProjects'
    }),
    slicedProjects() {
      return this.projects.slice(0, 3)
    },
    locale() {
      return getRouteLocale()
    }
  }
}
</script>
